import React, { HTMLAttributes, ReactNode } from "react";
import { FC } from "react";
import { PostCardFragment } from "@graphql/types";
import classNames from "classnames";
import { Title } from "@components/ui/Title";
import PostCard from "../PostCard";
import SwipeCards from "@components/common/Carousel/swipeCards";

type Props =
  | {
      orientation: "vertical";
      columns: "4" | "3" | "2";
      title?: string;
      data?: PostCardFragment[];
      showUser?: boolean;
      isLoading?: boolean;
      className: HTMLAttributes<HTMLDivElement>["className"];
      children?: ReactNode;
      enableSwipeOnMobile?: boolean;
      hasNextPage?: boolean;
      onLoadMore?: () => void;
    }
  | {
      orientation: "horizontal";
      rows: "4" | "3" | "2";
      title?: string;
      data?: PostCardFragment[];
      showUser?: boolean;
      isLoading?: boolean;
      className: HTMLAttributes<HTMLDivElement>["className"];
      children?: ReactNode;
      enableSwipeOnMobile?: boolean;
    };

const PostsBlock: FC<Props> = (payload) => {
  if (payload.data?.length === 0) {
    return null;
  }

  const cards = payload.data?.map((node) => (
    <PostCard
      key={node.id}
      post={node}
      showUser={payload.showUser}
      orientation={payload.orientation}
    />
  ));

  if (payload.orientation === "vertical") {
    const className = classNames(
      "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4",
      {
        "xl:grid-cols-4": payload.columns === "4",
        "lg:grid-cols-3": payload.columns === "3",
        "lg:grid-cols-2": payload.columns === "2",
      }
    );

    return (
      <div className="mb-8">
        {payload.title && (
          <Title as="h2" size="md" divider>
            {payload.title}
          </Title>
        )}
        {/* Mobile: If enableSwipeOnMobile is true, render SwipeCards (only visible on mobile due to block md:hidden) */}
        {payload.enableSwipeOnMobile && cards && (
          <div className="block md:hidden">
            <SwipeCards
              items={cards}
              slidesPerView={1.2}
              hasNextPage={payload.hasNextPage}
              onLoadMore={payload.onLoadMore}
              isLoading={payload.isLoading}
            />
          </div>
        )}
        {/* Desktop: If enableSwipeOnMobile is true, apply "hidden md:block" to hide grid on mobile; otherwise, show grid on all screen sizes */}
        <div
          className={classNames(
            payload.enableSwipeOnMobile ? "hidden md:grid" : "",
            className
          )}
        >
          {cards}
        </div>
        {payload.children && (
          <div className="text-center mt-8">{payload.children}</div>
        )}
      </div>
    );
  }

  if (payload.orientation === "horizontal") {
    return (
      <div className="flex flex-col w-full">
        {payload.title && (
          <Title as="h2" size="md" divider>
            {payload.title}
          </Title>
        )}
        {payload.enableSwipeOnMobile && cards && (
          <SwipeCards items={cards} slidesPerView={1.2} />
        )}
        <div
          className={classNames(
            "flex flex-col w-full",
            payload.enableSwipeOnMobile ? "hidden md:flex" : ""
          )}
        >
          {cards}
        </div>
        {payload.children && (
          <div className="text-center mt-8">{payload.children}</div>
        )}
      </div>
    );
  }

  return null;
};

export default PostsBlock;
